:root {
    --primary: #a71060;
    --secondary: #f6379b;
    --text-primary: #F6F6F6;
    --text-secondary: #c4c0c4;
    --background: #241e28; 
    --background-secondary: #211a21;
    --border: rgb(163, 163, 163);
    --error: #d32f2f;
    --background-light: #F6F6F6;
    --background-gray: #F1F4F5;
    --medium-gray: #7C7C7C;
    --light-blue: #42BBFF;
    --success: #009469;
}